import { app, provider } from '../../../firebase/index';
import { FaGoogle } from "react-icons/fa";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, createUserWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, doc, setDoc, serverTimestamp, getDoc, getDocs, collection, where, query  } from 'firebase/firestore';
import { RxCross1 } from "react-icons/rx";
import PawTexture from '../../1_MediaAssets/OtherBasics/Texture.png';
import CatPeeking from '../../1_MediaAssets/Homepage/PetStickers/catpeeking.png';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../Navbars/NavbarMain/NavbarMain';
import Logo from '../../1_MediaAssets/Homepage/Logo.png';

const DoctorProfilePublic = () => {
    const db = getFirestore();
    const location = useLocation();
    const navigate = useNavigate();
    const docId = location.state.docId;
    const auth = getAuth();
    const [selectedDay, setSelectedDay] = useState('');
    const [timingArray, setTimingArray] = useState([]);
    const [species, setSpecies] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [interests, setInterests] = useState([]); 
    const [selectedTiming, setSelectedTiming] = useState(null);
    const [doctor, setDoctor] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);
    const [showDatesandTimesPopUp, setShowDatesandTimesPopUp] = useState(false);
    const [showLoginPopUp, setShowLoginPopUp] = useState(false);
    const [showSignupPopUp, setShowSignupPopUp] = useState(false);
    // SIGN UP FORM POPUP
    const [name, setName] = useState('');
    const [emailSignup, setEmailSignup] = useState('');
    const [phone, setPhone] = useState('');
    const [state, setState] = useState('');
    const [passwordSignup, setPasswordSignup] = useState('');
    const [confirmPasswordSignup, setConfirmPasswordSignup] = useState('');
    // LOGIN FORM POPUP
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [bookedAppointments, setBookedAppointments] = useState([]);

    const handleLoginSubmit = async (e) => {
        document.getElementById("buttoonLogin").innerHTML = "Loading...";
        e.preventDefault();
        const auth = getAuth();
        if (!email || !password) {
            document.getElementById("messageLogin").innerHTML = "Fill in all fields!"
            document.getElementById("buttoonLogin").innerHTML = "Sign in";
            return;
        }
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const userDocRef = doc(db, 'Parents', user.uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const navigationState = {
                    state: {
                        DoctorID: docId,
                        DocType: doctor.DocType,
                        Rate: selectedTiming?.rate,
                        Date: selectedDate,
                        Time: selectedTiming?.time,
                    }
                };
                navigate('/AppointmentConfirmationPage', navigationState);

            } else {
                console.log('No such document!');
            }
        } catch (err) {
            document.getElementById("messageLogin").innerHTML = 'Failed to login. Please check your email and password.';
            document.getElementById("buttoonLogin").innerHTML = "Sign in";
            console.error('Login error:', err);
            return;
        }
    };
    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            localStorage.setItem('userId', user.uid);
            const docRef = doc(db, "Parents", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const navigationState = {
                    state: {
                        DoctorID: docId,
                        DocType: doctor.DocType,
                        Rate: selectedTiming?.rate,
                        Date: selectedDate,
                        Time: selectedTiming?.time,
                    }
                };

                navigate('/AppointmentConfirmationPage', navigationState);
                // navigate('/DashboardPetParents', { state: { user: docSnap.data() } });
            } else {
                document.getElementById("messageLogin").innerHTML = "No such user! Contact admin support@rexvets.com";
            }
        } catch (error) {
            console.error("Error during Google sign-in:", error);
            document.getElementById("messageLogin").innerHTML = "Error during Google sign-in.";
        }
    };
    const handleSignupSubmit = async (e) => {
        e.preventDefault();

        if (!name || !emailSignup || !phone || !state || !passwordSignup || !confirmPasswordSignup) {
             document.getElementById("messageSignup").innerHTML = 'Please fill all fields.';
            return;
        }
        if (passwordSignup.length < 8) {
            document.getElementById("messageSignup").innerHTML = 'Password must be at least 8 characters long.';
            return;
        }
        if (passwordSignup !== confirmPasswordSignup) {
            document.getElementById("messageSignup").innerHTML = 'Passwords do not match.';
            return;
        }

        try {
            const auth = getAuth();
            const userCredential = await createUserWithEmailAndPassword(auth, emailSignup, passwordSignup);
            const user = userCredential.user;

            await setDoc(doc(db, 'Parents', user.uid), {
                Name: name,
                EmailAddress: emailSignup,
                PhoneNo: phone,
                State: state,
                RegistrationTime: serverTimestamp(),
            });

            const navigationState = {
                state: {
                    DoctorID: docId,
                    DocType: doctor.DocType,
                    Rate: selectedTiming?.rate,
                    Date: selectedDate,
                    Time: selectedTiming?.time,
                }
            };
            navigate('/AppointmentConfirmationPage', navigationState);
            
            sendWelcomeEmail(emailSignup,name);
            handleClosePopUp();
        } catch (err) {
            console.error('Error signing up:', err);
            document.getElementById("messageSignup").innerHTML = 'Error';
        }
    };
    const handleGoogleSignUp = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const db = getFirestore();
            const userData = { 
                Name: user.displayName || 'Anonymous',
                EmailAddress: user.email,
                PhoneNo: '',
                State: '',
                RegistrationTime: serverTimestamp(),
            };

            await setDoc(doc(db, `Parents`, user.uid), userData);
            sendWelcomeEmail(user.email, user.displayName);
            const navigationState = {
                state: {
                    DoctorID: docId,
                    DocType: doctor.DocType,
                    Rate: selectedTiming?.rate,
                    Date: selectedDate,
                    Time: selectedTiming?.time,
                }
            };
            navigate('/AppointmentConfirmationPage', navigationState);
            // navigate('/RegistrationPetParentsPage2', { state: { userId: user.uid } });
        } catch (error) {
            document.getElementById("messageSignup").innerHTML = "Google Sign-In failed: " + error.message;
        }
    };
    function sendWelcomeEmail(email,name) {
        console.log("Sending welcome email for Google sign-up..."); // Log before sending email
        fetch("https://rexvetsemailserver.vercel.app/sendWelcomeEmailParent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                name: name || 'Anonymous',
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log("Email response:", data); // Log email response
        })
        .catch(emailError => {
            console.log("Error sending welcome email:", emailError); // Log email sending error
        });
    }
    function navigateUser() {
        navigate('/ForgotPasswordPetParents');
    }
    const currentDate = new Date().toISOString().split('T')[0];
    const handleClosePopUp = () => {
        setShowPopUp(false);
        setShowLoginPopUp(false);
        setShowSignupPopUp(false);
        setShowDatesandTimesPopUp(false);
    };
    const handleLoginPopUp = () => {
        setShowPopUp(false);
        setShowLoginPopUp(true);
        setShowSignupPopUp(false);
    }
    const handleSignupPopUp = () => {
        setShowPopUp(false);
        setShowSignupPopUp(true);
        setShowLoginPopUp(false);
    }
    const handleShowDatesandTimes = async () => {
        setShowDatesandTimesPopUp(true);
    }
    const handleBookButtonClick = async () => {
        setShowDatesandTimesPopUp(false);
        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                setShowPopUp(true);
                return;
            }

            const userDocRef = doc(db, "Parents", user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {

                const navigationState = {
                    state: {
                        DoctorID: docId,
                        DocType: doctor.DocType,
                        Rate: selectedTiming?.rate,
                        Date: selectedDate,
                        Time: selectedTiming?.time
                    }
                };

                navigate('/AppointmentConfirmationPage', navigationState); 
                
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching user document:", error);
        }
    };
    useEffect(() => {
        const today = new Date();
        
        const formattedDate = today.toLocaleDateString('en-CA');
    
        console.log(formattedDate, "Date Today");
        setSelectedDate(formattedDate);
    }, []);
    function optionClick(timing) {
        console.log("Before update:", selectedTiming);
        setSelectedTiming((prevSelectedTiming) => {
            const newTiming = prevSelectedTiming === timing ? null : timing;
            console.log("After update:", newTiming);
            return newTiming;
        });
    }
    useEffect(() => {
        async function fetchDoc() {
            let docRef;

            const techRef = doc(db, 'Doctors', 'Veterinarian', 'Veterinarian', docId);

            const techSnap = await getDoc(techRef);

            if (techSnap.exists()) {
                docRef = techRef;
            } else {
                console.log("No such document!");
                return;
            }

            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const doctorData = docSnap.data();
                setDoctor(doctorData);
                const timingArray = [];

                doctorData.Schedule.forEach(day => {
                    const dayTimings = day.timings.flatMap(({ from, until }) => {
                        const fromParts = from.split(' ');
                        const toParts = until.split(' ');

                        const fromTime = fromParts[0].split(':');
                        const toTime = toParts[0].split(':');

                        let fromHour = parseInt(fromTime[0]);
                        let fromMinute = parseInt(fromTime[1]);
                        let toHour = parseInt(toTime[0]);
                        let toMinute = parseInt(toTime[1]);

                        if (fromParts[1] === 'PM' && fromHour !== 12) {
                            fromHour += 12;
                        }
                        if (toParts[1] === 'PM' && toHour !== 12) {
                            toHour += 12;
                        }

                        const startTime = new Date(2022, 0, 1, fromHour, fromMinute);
                        const endTime = new Date(2022, 0, 1, toHour, toMinute);

                        const timings = [];

                        while (startTime < endTime) {
                            timings.push({
                                time: startTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                                rate: '0'
                            });
                            startTime.setMinutes(startTime.getMinutes() + 30);
                        }
                        return timings;
                    });

                    timingArray.push({ day: day.day, timings: dayTimings });
                });

                setTimingArray(timingArray);
                setSpecies(doctorData.SpeciesTreated || []);    
                setInterests(doctorData.Interests || []);    
                setLicenses(doctorData.urls || []);
            } else {
                console.log("No such document!");
            }
        }

        const fetchBookedAppointments = async (doctorId) => {
            const q = query(collection(db, 'Appointments'), where('DoctorRefID', '==', doctorId));
            const querySnapshot = await getDocs(q);
            let bookedAppointments = [];
            
            querySnapshot.forEach((doc) => {
              bookedAppointments.push(doc.data());
            });
          
            return bookedAppointments;
          };
          

        fetchDoc().then(() => {
            console.log("Doctor fetched");
            fetchBookedAppointments(docId).then((bookedAppointments) => {
                setBookedAppointments(bookedAppointments);
                console.log("Booked appointments fetched", bookedAppointments);
            }).catch((error) => {
                console.error("Error fetching booked appointments:", error);
            });

        }).catch((error) => {
            console.error("Error fetching doctor:", error);
        });
    }, [db, docId]);
    const isTimeSlotBooked = (bookedAppointments, selectedDate, timeSlot) => {
        return bookedAppointments.some(appointment =>
          appointment.AppointmentDate === selectedDate && appointment.AppointmentTime === timeSlot
        );
    };
    const handleDayChange = (event) => {
        const date = new Date(event.target.value);
        const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
        setSelectedDay(day);
        setSelectedDate(event.target.value);
    };
    const getCurrentDateTime = () => {
        const now = new Date();
        const currentDay = now.toLocaleDateString('en-US', { weekday: 'long' });
        const currentTime = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        return { currentDay, currentTime };
    };
    const isLaterTime = (time1, time2) => {
        const [hours1, minutes1, period1] = time1.split(/[:\s]/);
        const [hours2, minutes2, period2] = time2.split(/[:\s]/);
        let totalMinutes1 = (parseInt(hours1) % 12 + (period1 === 'PM' ? 12 : 0)) * 60 + parseInt(minutes1);
        let totalMinutes2 = (parseInt(hours2) % 12 + (period2 === 'PM' ? 12 : 0)) * 60 + parseInt(minutes2);
        return totalMinutes1 > totalMinutes2;
    };
    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString('en-US', options);
    };
    const findNextFourTimings = (timingArray) => {
        if (!timingArray || timingArray.length === 0) {
            return [];
        }
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let slots = [];
        let todayIndex = daysOfWeek.indexOf(getCurrentDateTime().currentDay);
        const currentTime = getCurrentDateTime().currentTime;
    
        for (let i = 0; i < daysOfWeek.length && slots.length < 4; i++) {
            const dayIndex = (todayIndex + i) % 7;
            const dayName = daysOfWeek[dayIndex];
            const dayTimings = timingArray.find(day => day.day === dayName);
    
            if (dayTimings) {
                const validTimings = dayTimings.timings.filter(timing =>
                    i > 0 || isLaterTime(timing.time, currentTime) // Skip current day
                );
                const date = new Date();
                date.setDate(date.getDate() + i);
    
                const dateFormatted = i === 0 ? '' : ` on ${formatDate(date)}`;
                const timingsWithDate = validTimings.map(timing => ({
                    ...timing,
                    displayTime: i === 0 ? `Today at ${timing.time}` : `${dateFormatted} at ${timing.time}`
                }));
    
                // Always skip the first valid slot for today
                if (i === 0 && timingsWithDate.length > 0) {
                    timingsWithDate.shift(); // Remove the first slot of today
                }
    
                slots = [...slots, ...timingsWithDate.slice(0, 4 - slots.length)];
            }
        }
        return slots;
    };
    const nextFourTimings = findNextFourTimings(timingArray);
    const calculateTotal = (rate) => {
        if (rate) {
            return (rate * 1.2).toFixed(2);
        }
        return '0.00';
    };
    if (!doctor) {
        return <div style={{height: "100vh", width: '100vw', background: '#002366', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img src={Logo} style={{width: '150px'}} alt='RexVets Logo' />
        </div>;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100%', background: '#fff', paddingTop: '20px'}}>
            <div className='dashboardmain2'>
                <Navbar />
                <div class="scroll-container">
                    <div className="vet-section">
                        <div className="vet-content">
                            <div className="vet-profile">
                                <img src={doctor.profilePictureURL} alt="Dr" className="vet-profile-img" style={{ objectFit: 'cover', border: '2px solid #ddd' }}></img>
                                <div className="vet-info">
                                    <h2 style={{fontFamily:'DM Sans, sans-serif', fontSize: '30px', color: '#404040'}}>Dr. {doctor.FirstName} {doctor.LastName}</h2>
                                    <p  style={{fontFamily:'Inter, sans-serif', fontSize:'14px', color:'#737373'}}>{doctor.DocType}, {doctor.PostNominalLetters} ・ <span className="rating"  style={{fontFamily:'Inter, sans-serif', fontSize:'14px', color:'#737373'}}><i className="fas fa-star"></i> 5.0 · {/* Reviews*/}<a    style={{fontFamily:'Inter, sans-serif', fontSize:'14px', color:'#737373', textDecoration:'underline', fontWeight:'300' }} href="#">37 reviews</a></span></p>
                                    <div className="license-verified moverAhora">
                                        <span className="license-badge">
                                            <i className="fa-solid fa-check"></i> License verified
                                            <span className="tooltip-text">This vet has passed an extensive verification process with their FL veterinarian license</span>
                                        </span>
                                    </div>
                                    <p className="vet-description" style={{fontFamily:'Inter, sans-serif'}}>{doctor.Bio}</p>
                                    
                                    <div className="species-treated">
                                        <h4 style={{fontFamily:'Inter, sans-serif'}}>Species treated</h4>
                                        <div style={{fontFamily:'Inter, sans-serif'}} className="species-icons">
                                            {species.map((item, index) => {
                                                let iconClass = 'fas fa-paw';
                                                if (item.type === 'Cats (Feline)') iconClass = 'fas fa-cat';
                                                if (item.type === 'Dogs (Canine)') iconClass = 'fas fa-dog';
                                                return (
                                                    item.checked && (
                                                        <div key={index} className="species">
                                                            <i className={iconClass}></i> {item.type}
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className="vet-areas-reviews">
                                        <div className="vet-areas-of-interest">
                                            <h4 style={{fontFamily:'Inter, sans-serif'}}>Areas of interest</h4>
                                            <ul className="vet-interest-list">
                                                {interests.map((item, index) => (
                                                    item.checked && (
                                                        <li key={index} style={{fontFamily:'Inter, sans-serif'}}>
                                                            <i className="fas fa-check colorCheck"></i> {item.type}
                                                        </li>
                                                    )
                                                ))}
                                            </ul>
                                        </div>

                                        <div className="vet-licenses">
                                            <h4 style={{fontFamily:'Inter, sans-serif', marginTop: '25px'}}>Licenses</h4>
                                            {licenses.map((url, index) => (
                                            <p key={index}><i className="fas fa-id-card"></i> {url.licenseState}</p>
                                            ))}
                                        </div>
                                        <div className="vet-reviews">
                                            <h4><i className="fas fa-star"></i> 5.0 · 37 reviews</h4>
                                            <div className="vet-reviews-list" style={{width: '100%'}}>
                                                <div className="vet-review">
                                                    <p style={{fontFamily:'Inter, sans-serif'}}>Dr. Tiffany was incredible with my dog! Very knowledgeable and caring. Highly recommend!</p>
                                                    <div className="vet-review-author">
                                                        <div className="vet-review-avatar">A</div>
                                                        <div className="vet-review-details">
                                                            <p style={{fontFamily:'Inter, sans-serif'}}>Alice Smith</p>
                                                            <p style={{fontFamily:'Inter, sans-serif'}}>September 2024</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vet-review">
                                                    <p style={{fontFamily:'Inter, sans-serif'}}>Dr. Tiffany made my cat's check-up stress-free. She’s patient and thorough!</p>
                                                    <div className="vet-review-author">
                                                        <div className="vet-review-avatar">J</div>
                                                        <div className="vet-review-details">
                                                            <p style={{fontFamily:'Inter, sans-serif'}}>James Taylor</p>
                                                            <p style={{fontFamily:'Inter, sans-serif'}}>August 2024</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vet-review">
                                                    <p style={{fontFamily:'Inter, sans-serif'}}>Fantastic vet! Dr. Tiffany was professional and made sure my puppy got the best care.</p>
                                                    <div className="vet-review-author">
                                                        <div className="vet-review-avatar">R</div>
                                                        <div className="vet-review-details">
                                                            <p style={{fontFamily:'Inter, sans-serif'}}>Rachel Lee</p>
                                                            <p style={{fontFamily:'Inter, sans-serif'}}>July 2024</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vet-review">
                                                    <p style={{fontFamily:'Inter, sans-serif'}}>Dr. Tiffany is amazing! She treated my rabbit and provided such great advice.</p>
                                                    <div className="vet-review-author">
                                                        <div className="vet-review-avatar">M</div>
                                                        <div className="vet-review-details">
                                                            <p style={{fontFamily:'Inter, sans-serif'}}>Mark Johnson</p>
                                                            <p style={{fontFamily:'Inter, sans-serif'}}>June 2024</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <button className="vet-show-all-button" style={{fontFamily:'Inter, sans-serif'}}>Show all 425 reviews</button> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vet-appointment">
                            <div className='appointmentStyling'>
                                <h3 className='text-center' style={{ fontFamily: 'Inter, sans-serif', fontSize:'18px' }}>Next available time slots</h3>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '5px' }}>
                                    {nextFourTimings.map((timing, index) => (
                                        console.log(timing,'tomomgimg'),
                                        <div
                                        
                                            key={`${timing.day}-${timing.time}`}
                                            style={{ border: selectedTiming && selectedTiming.time === timing.time ? "2px solid #27ae60" : "1px solid black", display: 'flex', cursor: 'pointer', textAlign: 'center', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '92%', height: '50px', borderRadius: '10px', padding: '30px 10px 30px 10px', margin:'0px 0px 10px' } }
                                            onClick={() => setSelectedTiming(timing)}
                                        >
                                            <p className="price" style={{ fontSize: '14px', fontFamily: 'Inter, sans-serif', color: '#171717' }}>{timing.displayTime}</p>
                                            <p className="price" style={{ fontSize: '14px', color: 'black', fontFamily: 'Inter, sans-serif'  }}>${timing.rate}</p>
                                        </div>
                                    ))}
                                </div>
                                <button onClick={handleShowDatesandTimes} className="show-more text-center" style={{ fontFamily: 'Inter, sans-serif', width: '100%', background: 'none', textDecoration: 'underline', fontWeight:'300', fontSize: '16px', color:'#171717' }}>Show more dates and times</button>
                                <div className='PaymentSectionPublic'>
                                    <p className="total">Total <span className="amount">${selectedTiming ? calculateTotal(selectedTiming.rate) : '0.00'}</span></p>
                                    <p className='taxestext'>Incl. tax & fees</p>
                                    <button onClick={handleBookButtonClick} className="continue-booking" style={{fontFamily:'Inter, sans-serif'}}>Continue booking</button>
                                    <p className='text-center taxestext' style={{padding:'3px 0px 20px 0px'}}>You won’t be charged yet</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showDatesandTimesPopUp && (
                <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', zIndex: '1000000', overflow: 'scroll'}}>
                    <RxCross1 style={{cursor: 'pointer', marginBottom: '10px', position: 'absolute', top: '10px', right: '10px', fontSize: '25px', fontWeight: '900', color: 'white'}} onClick={handleClosePopUp} />
                    
                    <div className='MainDivDoctorProfile'>
                        <div className='DocProfDivMainNew'>
                            <div className='DocProfDivRight' style={{ flex: '2' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                                    <input onChange={handleDayChange} type='date' min={currentDate} style={{ width: '100%', height: '50px', borderRadius: '7px', padding: '10px', marginBottom: '10px', border: '2px solid #ddd'}} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'row', marginTop: '10px', padding: '20px 0px 20px 0px', overflowY: 'scroll', flexWrap: 'wrap', gap: '10px', borderRadius: '10px', border: '1px solid #cdcfd4' }}>

                                    {timingArray.map((dayTimings, index) => (
                                        selectedDay === dayTimings.day &&
                                        dayTimings.timings.map((timing, index) => {
                                        const isBooked = bookedAppointments.some(
                                            (appointment) => appointment.AppointmentDate === selectedDate && appointment.AppointmentTime === timing.time
                                        );

                                        console.log(selectedDate,bookedAppointments[0].AppointmentDate, timing.time, bookedAppointments[0].AppointmentTime, isBooked);
                                        
                                        return (
                                            <div
                                            key={index}
                                            style={{
                                                background: selectedTiming === timing ? "#FFE699" : isBooked ? "#f0f0f0" : "#fff",
                                                display: 'flex',
                                                cursor: isBooked ? 'not-allowed' : 'pointer',
                                                textAlign: 'center',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '45%',
                                                height: '45px',
                                                borderRadius: '7px',
                                                border: '1px solid #ddd',
                                                fontSize: '13px',
                                                color: isBooked ? '#aaa' : '#000',
                                            }}
                                            onClick={() => !isBooked && optionClick(timing)}
                                            >
                                            {timing.time} <br />
                                            <span style={{ fontSize: '13px', color: isBooked ? '#aaa' : '#2458b8', marginTop: '-5px' }}>
                                                ${timing.rate}
                                            </span>
                                            </div>
                                        );
                                        })
                                    ))}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <button style={{ width: '100%', height: '50px', borderRadius: '7px', padding: '10px', marginTop: '10px', background: selectedTiming ? '#0849c1' : '#ddd', color: selectedTiming ? '#fff' : '#555', cursor: selectedTiming ? 'pointer' : 'not-allowed', border: 'none' }} disabled={!selectedTiming} onClick={handleBookButtonClick} >
                                        Book now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )}
            {showPopUp && (
                <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '1000000'}}>
                    <RxCross1 style={{cursor: 'pointer', marginBottom: '10px', position: 'absolute', top: '10px', right: '10px', fontSize: '25px', fontWeight: '900', color: 'white'}} onClick={handleClosePopUp} />
                    <div style={{minHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative', background: '#fff', padding: '20px', borderRadius: '10px', width: '95vw', maxWidth: '800px', backgroundImage:`url('${PawTexture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                        <img alt="CatPeeking" src={CatPeeking} style={{position: 'absolute', top: '-4px', right: '40px', height: '40px'}}/>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', gap: '10px'}}>
                            <button onClick={handleLoginPopUp} style={{width: '150px', height: '100px'}}>Login</button>
                            <button onClick={handleSignupPopUp} style={{width: '150px', height: '100px'}}>Sign up</button>
                        </div>
                    </div>
                </div>
            )}
            {showSignupPopUp && (
                <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '1000000'}}>
                    <RxCross1 style={{cursor: 'pointer', marginBottom: '10px', position: 'absolute', top: '10px', right: '10px', fontSize: '25px', fontWeight: '900', color: 'white'}} onClick={handleClosePopUp} />
                    <form onSubmit={handleSignupSubmit} style={formStyle}>
                        <h3 style={{fontSize: '25px', width: '100%', textAlign: 'center'}}>Sign Up</h3>
                        <button onClick={handleGoogleSignUp} style={{ width: '100%', margin: '10px 0px', backgroundColor: '#4285F4', color: 'white' }}><FaGoogle style={{margin: '0', padding: '0', fontSize: '20px', marginRight: '10px'}}/> Sign up with Google</button>
                        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} style={inputStyle} />
                        <input type="email" placeholder="Email" value={emailSignup} onChange={(e) => setEmailSignup(e.target.value)} style={inputStyle} />
                        <input type="tel" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} style={inputStyle} />
                        <input type="text" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} style={inputStyle} />
                        <input type="password" placeholder="Password" value={passwordSignup} onChange={(e) => setPasswordSignup(e.target.value)} style={inputStyle} />
                        <input type="password" placeholder="Confirm Password" value={confirmPasswordSignup} onChange={(e) => setConfirmPasswordSignup(e.target.value)} style={inputStyle} />
                        <div id='messageSignup' style={{ margin: "2px 0px", color: 'red', fontSize: '11px', width: '100%', textAlign: 'center', height: '13px' }}></div>
                        <button id='buttoonSignup' type="submit" style={buttonStyle}>Sign Up</button>
                    </form>
                </div>
            )}
            {showLoginPopUp && (
                <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '1000000'}}>
                    <RxCross1 style={{cursor: 'pointer', marginBottom: '10px', position: 'absolute', top: '10px', right: '10px', fontSize: '25px', fontWeight: '900', color: 'white'}} onClick={handleClosePopUp} />
                    <form onSubmit={handleLoginSubmit} style={formStyle}>
                        <h3 style={{fontSize: '25px', width: '100%', textAlign: 'center'}}>Log in</h3>
                        <button onClick={handleGoogleSignIn} style={{ width: '100%', margin: '10px 0px', backgroundColor: '#4285F4', color: 'white' }}><FaGoogle style={{margin: '0', padding: '0', fontSize: '20px', marginRight: '10px'}}/> Sign in with Google</button>
                        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} style={inputStyle} />
                        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} style={inputStyle} />
                        <p onClick={navigateUser} style={{ marginTop: "0px", textDecoration: 'underline', color: 'gray', cursor: 'pointer', fontSize: '10px', marginBottom: '5px' }}>Forgot Password</p>
                        <div id='messageLogin' style={{ margin: "2px 0px", color: 'red', fontSize: '11px', width: '100%', textAlign: 'center', height: '13px' }}></div>
                        <button id='buttoonLogin' type="submit" style={buttonStyle}>Login</button>
                    </form>
                </div>
            )}
        </div>
    );
}

const formStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px'
};

const inputStyle = {
    width: '100%',
    height: '35px',
    fontSize: '13px',
    padding: '10px',
    margin: '5px 0',
    borderRadius: '4px',
    border: '1px solid #ddd'
};

const buttonStyle = {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: 'none',
    color: '#fff',
    fontSize: '13px',
    cursor: 'pointer',
    marginTop: '10px',
    height: '40px'
};
// eslint-disable-next-line
const errorStyle = {
    color: 'red',
    height: '13px',
    fontSize: '11px',
    width: '100%',
    textAlign: 'center',
    margin: '5px 0px'
};
export default DoctorProfilePublic;