import { GiAngelOutfit } from "react-icons/gi";
import { FiEdit2 } from "react-icons/fi";
import NavbarPetParentsDashboard from '../../../Navbars/NavbarPetParentsDashboard/index.js';
import { IoMdAddCircleOutline } from "react-icons/io";
import PetDP from '../../../1_MediaAssets/OtherBasics/pet.png'; 
import { MdOutlinePets } from "react-icons/md";
import { useEffect, useState } from 'react';
import { doc, getDoc, collection, getFirestore, addDoc, updateDoc, onSnapshot, getDocs } from "firebase/firestore";
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { RxCross1 } from "react-icons/rx";
import PawTexture from '../../../1_MediaAssets/OtherBasics/Texture.png';
import CatPeeking from '../../../1_MediaAssets/Homepage/PetStickers/catpeeking.png'; 

const PetsPetParents = () => { 
    // eslint-disable-next-line
    const [parentData, setParentData] = useState({});
    const [showPopUp, setShowPopUp] = useState(false); 
    const [showEditPopUp, setShowEditPopUp] = useState(false); 
    const [showRemovePopUp, setShowRemovePopUp] = useState(false);
    const [pets, setPets] = useState([]);
    const [selectedPet, setSelectedPet] = useState(null);
    // eslint-disable-next-line
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const db = getFirestore();
    // eslint-disable-next-line
    const location = useLocation();
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');

    
    const auth = getAuth();

    useEffect(() => {
        const auth = getAuth();

        // Check if the user is authenticated
        const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setIsLoggedIn(true);
                setUserId(user.uid);

                try {
                    // Fetch parent data
                    const docRef = doc(db, "Parents", user.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setParentData(docSnap.data());
                    } else {
                        console.error('No such document!');
                    }

                    // Set up real-time listener for pets collection
                    const petsCollection = collection(docRef, 'pets');
                    const unsubscribePets = onSnapshot(petsCollection, (snapshot) => {
                        const petsArray = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setPets(petsArray);
                    });

                    // Cleanup listener on unmount
                    return () => {
                        unsubscribePets();
                        unsubscribeAuth();
                    };

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                setIsLoggedIn(false);
                navigate('/login');
            }
        });

    }, [db, navigate]);

    const handleClosePopUp = () => {
        setShowPopUp(false);
    };

    const handleSubmit = () => {
        setShowPopUp(true);
    };

    const handleEditPet = (id) => {
        const pet = pets.find(pet => pet.id === id);
        setSelectedPet(pet);
        setShowEditPopUp(true);
    };
    const handleCloseEditPopUp = () => {
        setShowEditPopUp(false);
        setSelectedPet(null);
    };

    const handleRemovePet = (id) => {
        const pet = pets.find(pet => pet.id === id);
        setSelectedPet(pet);
        setShowRemovePopUp(true);
    };
    const handleCloseRemovePopUp = () => {
        setShowRemovePopUp(false);
        setSelectedPet(null);
    };

    

    const submit = async (e) => {
        e.preventDefault();
        
        const Name = document.getElementById('name').value;
        const Species = document.getElementById('species').value;
        const PetColor = document.getElementById('color').value;
        const Gender = document.getElementById('gender').value;
        const Breed = document.getElementById('breed').value;
        const Weight = document.getElementById('weight').value;
        const WeightUnit = document.getElementById('weightunit').value;
        const Sprayed = document.getElementById('sprayed').value;
        const dobValue = document.getElementById('dob').value;
        
        if (!Name || !Weight || !Species || !Gender || !Breed || !Sprayed || !dobValue || !PetColor) {
            document.getElementById("errortext2").innerHTML = "Input all fields!";
            return;
        }
        
        const DOB = new Date(dobValue);
        const today = new Date();
        const birthDate = new Date(DOB);
        let ageYears = today.getFullYear() - birthDate.getFullYear();
        let ageMonths = today.getMonth() - birthDate.getMonth();
        
        if (ageMonths < 0) {
            ageYears--;
            ageMonths += 12;
        }
        
        const petData = { Name, Weight, WeightUnit, Species, Gender, Breed, Sprayed, AgeYears: ageYears, AgeMonths: ageMonths, PetColor };
        const user = auth.currentUser;
        
        if (user) {
            try {
                const petsCollection = collection(db, 'Parents', user.uid, 'pets');
                await addDoc(petsCollection, petData);
                handleClosePopUp();
                const petsSnapshot = await getDocs(petsCollection);
                const updatedPetsList = petsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPets(updatedPetsList);
            } catch (error) {
                console.error("Error adding pet:", error);
            }
        }
    };

    const submit2 = async (e) => {
        document.getElementById("editpetbutton").innerHTML = "Updating...";
        e.preventDefault();
        if (!selectedPet) return;

        const { id, ...petData } = selectedPet;
        const petDocRef = doc(db, 'Parents', userId, 'pets', id);

        await updateDoc(petDocRef, petData);
        handleCloseEditPopUp();
        document.getElementById("editpetbutton").innerHTML = "Submit";
    };

    const submit3 = async (e) => {
        document.getElementById("removepetbutton").innerHTML = "Updating...";
        e.preventDefault();
        if (!selectedPet) return;
    
        const reason = document.getElementById('RemovePet').value;
        const petDocRef = doc(db, 'Parents', userId, 'pets', selectedPet.id);
    
        try {
            await updateDoc(petDocRef, {
                RemovePet: reason,
                Deceased: "Yes"
            });
            handleCloseRemovePopUp();
        } catch (error) {
            console.error("Error updating document:", error);
        } finally {
            document.getElementById("removepetbutton").innerHTML = "Submit";
        }
    };
    

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setSelectedPet({ ...selectedPet, [id]: value });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', minHeight: '100vh' }}>
            <div className='dashboardmain'>
                <NavbarPetParentsDashboard />
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px', marginBottom: '200px', boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)'}}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '80%', padding: '0', margin: '0'}}>
                            <h4 style={{padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900'}}>
                                <MdOutlinePets style={{fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1'}}/>
                                Your pets
                            </h4>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexDirection: 'column', width: '20%', padding: '0', margin: '0'}}>
                            <button onClick={handleSubmit} style={{padding: '10px', width: '120px', border: '2px solid #002366', background: 'white', color: '#002366', fontWeight: '900'}}>Add pet<IoMdAddCircleOutline style={{height: '20px', width: '20px', borderRadius: '100px', margin: '0px 0px 1px 5px', color: '#002366'}}/></button>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', paddingBottom: '50px'}}>
                        <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'flex-start', alignItems: 'center'}}>
                            {pets.map((pet, index) => (
                                <div className='PetDetailDiv' key={index}>
                                    <img src={pet?.photoURL || PetDP} alt="pet" style={{width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover'}}/>
                                    <h5 style={{margin: '10px 0 0 0', fontWeight: 'bold', fontSize: '16px'}}>{pet.Name}</h5>
                                    <p style={{margin: '5px 0', color: '#555', fontSize: '13px'}}>{pet.Species}, {pet.AgeYears} years old</p>
                                    <button onClick={() => handleEditPet(pet.id)} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding:'10px', borderRadius: '200px', border: 'none', background: '#0849c1', color: '#000', cursor: 'pointer', marginTop: '10px', position: 'absolute', top: '0', right: '10px', backgroundColor: '#fff'}}><FiEdit2 /></button>
                                    <button onClick={() => handleRemovePet(pet.id)} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding:'10px', borderRadius: '200px', border: 'none', background: '#0849c1', color: '#000', cursor: 'pointer', marginTop: '10px', position: 'absolute', bottom: '10px', right: '10px', backgroundColor: '#fff'}}><GiAngelOutfit /></button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            
            {showPopUp && (
                <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '1000000'}}>
                    <RxCross1 style={{cursor: 'pointer', marginBottom: '10px', position: 'absolute', top: '10px', right: '10px', fontSize: '25px', fontWeight: '900', color: 'white'}} onClick={handleClosePopUp} />
                    <div style={{position: 'relative', background: '#fff', padding: '20px', borderRadius: '10px', width: '95vw', maxWidth: '800px', backgroundImage:`url('${PawTexture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                        <img alt="CatPeeking" src={CatPeeking} style={{position: 'absolute', top: '-4px', right: '40px', height: '40px'}}/>
                        <h3 className='fontSpecial'style={{color: '#002366', fontSize: '20px'}}>Add Pet</h3>
                        <form onSubmit={submit} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '7px', marginTop: '15px'}}>
                            
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
                                <input type="text" id="name" placeholder="Name" style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}/>
                                <select id="species" required style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}>
                                    <option disabled selected value="">Species</option>
                                    <option value="Dog">Dog</option>
                                    <option value="Cat">Cat</option>
                                    <option value="Horse">Horse</option>
                                    <option value="Bird">Bird</option>
                                    <option value="Rabbit">Rabbit</option>
                                    <option value="Small Mammal">Small Mammal</option>
                                    <option value="Reptile">Reptile</option>
                                    <option value="Amphibian">Amphibian</option>
                                    <option value="Spider">Spider</option>
                                    <option value="Fish">Fish</option>
                                    <option value="Farm Animal">Farm Animal</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
                                <input type="text" id="breed" placeholder="Breed" style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}/>
                                <select id="gender" required style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}>
                                    <option disabled selected value="">Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
                                <select id="color" required style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}>
                                    <option disabled selected value="">Primary Color</option>
                                    <option value="Black">Black</option>
                                    <option value="Brown">Brown</option>
                                    <option value="Gold">Gold</option>
                                    <option value="White">White</option>
                                    <option value="Cream">Cream</option>
                                    <option value="Blue">Blue</option>
                                    <option value="Gray">Gray</option>
                                    <option value="Red">Red</option>
                                    <option value="Yellow">Yellow</option>
                                    <option value="Green">Green</option>
                                    <option value="Orange">Orange</option>
                                </select>
                                <select id="sprayed" required style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}>
                                    <option disabled selected value="">Is your pet sprayed or neutered?</option>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
                                <input type="text" id="weight" placeholder="Weight" style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}/>
                                <select id="weightunit" style={{width: '60px', height: '35px', margin: '0', border: "1px solid #ddd", borderRadius: '5px'}}>
                                    <option value="lbs">lbs</option>
                                    <option value="kg">kg</option>
                                </select>
                            </div>
                            
                            <input type="date" id="dob" required style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }} />
                            <div id="errortext" style={{color: 'red', fontSize: '10px', height: '15px'}}></div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
                                <button onClick={handleClosePopUp} style={{width: '100%', background: 'transparent', border: '1px solid #002366', color: '#002366'}}>Cancel</button>
                                <button type="submit" id="submitpetdetails" style={{width: '100%', border: '1px solid #002366'}}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {showEditPopUp && selectedPet && (
                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '1000000' }}>
                    <RxCross1 style={{ cursor: 'pointer', marginBottom: '10px', position: 'absolute', top: '10px', right: '10px', fontSize: '25px', fontWeight: '900', color: 'white' }} onClick={handleCloseEditPopUp} />
                    <div style={{ position: 'relative', background: '#fff', padding: '20px', borderRadius: '10px', width: '95vw', maxWidth: '800px', backgroundImage: `url('${PawTexture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                        <img alt="CatPeeking" src={CatPeeking} style={{ position: 'absolute', top: '-4px', right: '40px', height: '40px' }} />
                        <h3 className='fontSpecial' style={{ color: '#002366', fontSize: '20px' }}>Edit Pet</h3>
                        <form onSubmit={submit2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '7px', marginTop: '15px' }}>
                            
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px' }}>
                                <input type="text" id="Name" placeholder="Name" value={selectedPet.Name} onChange={handleInputChange} style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }} />
                                <select id="Species" required value={selectedPet.Species} onChange={handleInputChange} style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }}>
                                    <option disabled value="">Species</option>
                                    <option value="Dog">Dog</option>
                                    <option value="Cat">Cat</option>
                                    <option value="Horse">Horse</option>
                                    <option value="Bird">Bird</option>
                                    <option value="Rabbit">Rabbit</option>
                                    <option value="Small Mammal">Small Mammal</option>
                                    <option value="Reptile">Reptile</option>
                                    <option value="Amphibian">Amphibian</option>
                                    <option value="Spider">Spider</option>
                                    <option value="Fish">Fish</option>
                                    <option value="Farm Animal">Farm Animal</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px' }}>
                                <input type="text" id="Breed" placeholder="Breed" value={selectedPet.Breed} onChange={handleInputChange} style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }} />
                                <select id="Gender" required value={selectedPet.Gender} onChange={handleInputChange} style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }}>
                                    <option disabled value="">Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px' }}>
                                <select id="PetColor" value={selectedPet.PetColor} onChange={handleInputChange} required style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }}>
                                    <option disabled value="">Primary Color</option>
                                    <option value="Black">Black</option>
                                    <option value="Brown">Brown</option>
                                    <option value="Gold">Gold</option>
                                    <option value="White">White</option>
                                    <option value="Cream">Cream</option>
                                    <option value="Blue">Blue</option>
                                    <option value="Gray">Gray</option>
                                    <option value="Red">Red</option>
                                    <option value="Yellow">Yellow</option>
                                    <option value="Green">Green</option>
                                    <option value="Orange">Orange</option>
                                </select>
                                <select id="Sprayed" required value={selectedPet.Sprayed} onChange={handleInputChange} style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }}>
                                    <option disabled value="">Is your pet sprayed or neutered?</option>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px' }}>
                                <input type="text" id="Weight" placeholder="Weight" value={selectedPet.Weight} onChange={handleInputChange} style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }} />
                                <select id="WeightUnit" value={selectedPet.WeightUnit} onChange={handleInputChange} style={{ width: '60px', height: '35px', margin: '0', border: "1px solid #ddd", borderRadius: '5px' }}>
                                    <option value="lbs">lbs</option>
                                    <option value="kg">kg</option>
                                </select>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px' }}>
                                <input type="text" id="AgeYears" placeholder="Age (years)" value={selectedPet.AgeYears} onChange={handleInputChange} style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }} />
                                <select id="AgeMonths" required value={selectedPet.AgeMonths} onChange={handleInputChange} style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", maxWidth: '200px', height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }}>
                                    <option disabled value="">Age (months)</option>
                                    <option value="00">00</option>
                                    <option value="01">01</option>
                                    <option value="02">02</option>
                                    <option value="03">03</option>
                                    <option value="04">04</option>
                                    <option value="05">05</option>
                                    <option value="06">06</option>
                                    <option value="07">07</option>
                                    <option value="08">08</option>
                                    <option value="09">09</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                            <div id="errortext" style={{ color: 'red', fontSize: '10px', height: '15px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px' }}>
                                <button onClick={handleCloseEditPopUp} style={{ width: '100%', background: 'transparent', border: '1px solid #002366', color: '#002366' }}>Cancel</button>
                                <button id="editpetbutton" type="submit" style={{ width: '100%', border: '1px solid #002366' }}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {showRemovePopUp && selectedPet && (
                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '1000000' }}>
                    <RxCross1 style={{ cursor: 'pointer', marginBottom: '10px', position: 'absolute', top: '10px', right: '10px', fontSize: '25px', fontWeight: '900', color: 'white' }} onClick={handleCloseRemovePopUp} />
                    <div style={{ position: 'relative', background: '#fff', padding: '20px', borderRadius: '10px', width: '95vw', maxWidth: '800px', backgroundImage: `url('${PawTexture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                        <img alt="CatPeeking" src={CatPeeking} style={{ position: 'absolute', top: '-4px', right: '40px', height: '40px' }} />
                        <h3 className='fontSpecial' style={{ color: '#002366', fontSize: '20px' }}>Remove Pet</h3>
                        <form onSubmit={submit3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '7px', marginTop: '15px' }}>
                            <p style={{width: '100%', fontSize: '13px', textAlign: 'left'}}>Please choose a reason to remove your pet..</p>
                            <select id="RemovePet" required style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }}>
                                <option disabled selected value="">Choose</option>
                                <option value="Deceased">Deceased</option>
                                <option value="I lost my pet">I lost my pet</option>
                                <option value="I gave my pet to someone else">I gave my pet to someone else</option>
                            </select>
                            <div id="errortext" style={{ color: 'red', fontSize: '10px', height: '15px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px' }}>
                                <button onClick={handleCloseRemovePopUp} style={{ width: '100%', background: 'transparent', border: '1px solid #002366', color: '#002366' }}>Cancel</button>
                                <button id="removepetbutton" type="submit" style={{ width: '100%', border: '1px solid #002366' }}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PetsPetParents;
