import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc, getFirestore } from "firebase/firestore";
import NavbarPetParentsDashboard from '../../../Navbars/NavbarPetParentsDashboard/index.js';
import { MdAccountCircle } from "react-icons/md";
import Logo from '../../../1_MediaAssets/Homepage/Logo.png'

const AccountPetParents = () => {
    const [userData, setUserData] = useState({
        Name: '',
        EmailAddress: '',
        PhoneNo: '',
        State: '',
        AppointmentsRemaining: 0
    });
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const docRef = doc(db, "Parents", user.uid);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        setUserData(docSnap.data());
                    } else {
                        console.error('No such document!');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                // Handle the case where the user is not authenticated
                console.log('User not authenticated');
            }
            setLoading(false);
        });

        // Cleanup subscription on component unmount
        return () => unsubscribe();
    }, [auth, db]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSave = async () => {
        document.getElementById("Buttony").innerHTML = "Loading...";
        const user = auth.currentUser;

        if (user) {
            try {
                const docRef = doc(db, "Parents", user.uid);
                await updateDoc(docRef, {
                    Name: userData.Name,
                    PhoneNo: userData.PhoneNo,
                    State: userData.State
                });
                document.getElementById("error").style.color = "green";
                document.getElementById("error").innerHTML = "Changes saved successfully!";
                setTimeout(() => {
                    document.getElementById("error").innerHTML = "";
                }, 5000);
                setIsEditing(false);
            } catch (error) {
                console.error('Error updating profile:', error);
            } finally {
                document.getElementById("Buttony").innerHTML = "Save Changes";
            }
        } else {
            // setError('User not authenticated');
        }
    };

    if (loading) {
        return <div style={{height: "100vh", width: '100vw', background: '#002366', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img src={Logo} style={{width: '150px'}} alt='RexVets Logo' />
        </div>;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100vh', background: '#f5f5f5' }}>
            <div className='dashboardmain'>
                <NavbarPetParentsDashboard />

                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px',   boxShadow: '0 4px 8px rgba(0,0,0,0.1)', maxWidth: '75%' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <h4 style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900' }}>
                            <MdAccountCircle style={{ fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
                            Account Settings
                        </h4>
                    </div>

                    <div style={{ width: '90%', marginTop: '20px' }}>
                        <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <label>
                                Name:
                                <input type="text" name="Name" value={userData.Name} onChange={handleInputChange} disabled={!isEditing} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} />
                            </label>
                            <label>
                                Email Address:
                                <input type="email" name="EmailAddress" value={userData.EmailAddress} disabled style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd', backgroundColor: '#f0f0f0' }} />
                            </label>
                            <label>
                                Phone Number:
                                <input type="tel" name="PhoneNo" value={userData.PhoneNo} onChange={handleInputChange} disabled={!isEditing} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} />
                            </label>
                            <label>
                                State:
                                <select name="State" value={userData.State} onChange={handleInputChange} disabled={!isEditing} required style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}>
                                    <option disabled value="">Select a state</option>
                                    <option value="Alabama">Alabama</option>
                                    <option value="Alaska">Alaska</option>
                                    <option value="Arizona">Arizona</option>
                                    <option value="Arkansas">Arkansas</option>
                                    <option value="California">California</option>
                                    <option value="Colorado">Colorado</option>
                                    <option value="Connecticut">Connecticut</option>
                                    <option value="Delaware">Delaware</option>
                                    <option value="District Of Columbia">District Of Columbia</option>
                                    <option value="Florida">Florida</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Idaho">Idaho</option>
                                    <option value="Illinois">Illinois</option>
                                    <option value="Indiana">Indiana</option>
                                    <option value="Iowa">Iowa</option>
                                    <option value="Kansas">Kansas</option>
                                    <option value="Kentucky">Kentucky</option>
                                    <option value="Louisiana">Louisiana</option>
                                    <option value="Maine">Maine</option>
                                    <option value="Maryland">Maryland</option>
                                    <option value="Massachusetts">Massachusetts</option>
                                    <option value="Michigan">Michigan</option>
                                    <option value="Minnesota">Minnesota</option>
                                    <option value="Mississippi">Mississippi</option>
                                    <option value="Missouri">Missouri</option>
                                    <option value="Montana">Montana</option>
                                    <option value="Nebraska">Nebraska</option>
                                    <option value="Nevada">Nevada</option>
                                    <option value="New Hampshire">New Hampshire</option>
                                    <option value="New Jersey">New Jersey</option>
                                    <option value="New Mexico">New Mexico</option>
                                    <option value="New York">New York</option>
                                    <option value="North Carolina">North Carolina</option>
                                    <option value="North Dakota">North Dakota</option>
                                    <option value="Ohio">Ohio</option>
                                    <option value="Oklahoma">Oklahoma</option>
                                    <option value="Oregon">Oregon</option>
                                    <option value="Pennsylvania">Pennsylvania</option>
                                    <option value="Rhode Island">Rhode Island</option>
                                    <option value="South Carolina">South Carolina</option>
                                    <option value="South Dakota">South Dakota</option>
                                    <option value="Tennessee">Tennessee</option>
                                    <option value="Texas">Texas</option>
                                    <option value="Utah">Utah</option>
                                    <option value="Vermont">Vermont</option>
                                    <option value="Virginia">Virginia</option>
                                    <option value="Washington">Washington</option>
                                    <option value="West Virginia">West Virginia</option>
                                    <option value="Wisconsin">Wisconsin</option>
                                    <option value="Wyoming">Wyoming</option>
                                </select>
                            </label>
                            <label>
                                Appointments Remaining:
                                <input type="text" value={userData.AppointmentsRemaining} readOnly style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd', backgroundColor: '#f0f0f0' }} />
                            </label>

                            <div id='error' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '0', margin: '0', fontSize: '13px', height: '15px'}}></div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px', gap: '5px' }}>
                                {!isEditing ? (
                                    <button type="button" onClick={() => setIsEditing(true)} style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#0849c1', color: '#fff', cursor: 'pointer', width: '100%' }}>
                                        Edit Profile
                                    </button>
                                ) : (
                                    <button id="Buttony" type="button" onClick={handleSave} style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#28a745', color: '#fff', cursor: 'pointer', width: '100%' }}>
                                        Save Changes
                                    </button>
                                )}
                                {isEditing && (
                                    <button type="button" onClick={() => setIsEditing(false)} style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#dc3545', color: '#fff', cursor: 'pointer', width: '100%' }}>
                                        Cancel
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountPetParents;
