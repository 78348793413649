import { provider } from '../../../firebase/index';
import { getAuth, createUserWithEmailAndPassword, signOut, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, serverTimestamp, getFirestore, getDoc } from 'firebase/firestore';
import { FaGoogle } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import '../../1_MediaAssets/Styles/App.css';
import '../../1_MediaAssets/Styles/Pets.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Sticky from 'react-stickynode';
import logo from '../../1_MediaAssets/Homepage/Logo.png';
import logoWhite from '../../1_MediaAssets/Homepage/Logo.png';
import { useNavigate } from 'react-router-dom';
import './Navbar.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faVideo, faSignInAlt } from '@fortawesome/free-solid-svg-icons';

const NavbarMain = () => {
    const auth = getAuth();
    const db = getFirestore();
    // eslint-disable-next-line
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [isMobileView, setIsMobileView] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [activeTab, setActiveTab] = useState('login');
    const [name, setName] = useState('');
    const [emailSignup, setEmailSignup] = useState('');
    const [phone, setPhone] = useState('');
    const [state, setState] = useState('');
    const [passwordSignup, setPasswordSignup] = useState('');
    const [confirmPasswordSignup, setConfirmPasswordSignup] = useState('');
    const [user, setUser] = useState(null);
	const [profilePictureURL, setProfilePictureURL] = useState('');
	const [loadingProfilePicture, setLoadingProfilePicture] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userDocType, setUserDocType] = useState('');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, [auth]);

	useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                try {
                    let docRef = doc(db, "Parents", currentUser.uid);
                    let docSnap = await getDoc(docRef);
    
                    if (docSnap.exists()) {
                        setUserDocType('Parent');
                    } else {
                        docRef = doc(db, "Doctors", "Veterinarian", "Veterinarian", currentUser.uid);
                        docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            setUserDocType('Veterinarian');
                        } else {
                            docRef = doc(db, "Doctors", "Technician", "Technician", currentUser.uid);
                            docSnap = await getDoc(docRef);
                            if (docSnap.exists()) {
                                setUserDocType('Technician');
                            } else {
                                setUserDocType('');
                            }
                        }
                    }
    
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setProfilePictureURL(data.profilePictureURL || '');
                    } else {
                        setProfilePictureURL('');
                    }
                } catch (error) {
                    console.error('Error fetching user profile:', error);
                }
                setLoadingProfilePicture(false);
            } else {
                setUser(null);
                setProfilePictureURL('');
                setLoadingProfilePicture(false);
            }
        });
    
        return () => unsubscribe();
    }, [auth, db]);
    
	

    const handleSignOut = () => {
        signOut(auth).then(() => {
            localStorage.removeItem('userId');
            console.log('Signed Out');
            window.location.href = '/';
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleClosePopUp = () => { setShowPopUp(false); };

    const handleShowPopUp = () => { setShowPopUp(true); };

    const handleTabSwitch = (tab) => { setActiveTab(tab); };

	const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            localStorage.setItem('userId', user.uid);
            const docRef = doc(db, "Parents", user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setShowPopUp(false);
            } else {
                document.getElementById("messageLogin").innerHTML = "No such user! Contact admin support@rexvets.com";
            }
        } catch (error) {
            console.error("Error during Google sign-in:", error);
            document.getElementById("messageLogin").innerHTML = "Error during Google sign-in.";
        }
    };

	const handleGoogleSignUp = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const db = getFirestore();
            const userData = { 
                Name: user.displayName || 'Anonymous',
                EmailAddress: user.email,
                PhoneNo: '',
                State: '',
                RegistrationTime: serverTimestamp(),
            };
            await setDoc(doc(db, `Parents`, user.uid), userData);
            sendWelcomeEmail(user.email, user.displayName);
			setShowPopUp(false);
        } catch (error) {
            document.getElementById("messageSignup").innerHTML = "Google Sign-In failed: " + error.message;
        }
    };
	const handleLoginSubmit = async (e) => {
		e.preventDefault();
		document.getElementById("buttoonLogin").innerHTML = "Loading...";
	
		if (!email || !password) {
			document.getElementById("messageLogin").innerHTML = "Fill in all fields!";
			document.getElementById("buttoonLogin").innerHTML = "Sign in";
			return;
		}
	
		try {
			const userCredential = await signInWithEmailAndPassword(auth, email, password);
			const user = userCredential.user;
	
			console.log(user, "user");
			console.log(user.uid, "user.uid");
			let userDocRef = doc(db, 'Parents', user.uid);
			let userDoc = await getDoc(userDocRef);
	
			if (!userDoc.exists()) {
				console.log("If not found, try fetching the user as a Veterinarian");
				userDocRef = doc(db, "Doctors", "Veterinarian", "Veterinarian", user.uid);
				userDoc = await getDoc(userDocRef);
				console.log("If not found, try fetching the user as a Veterinarian 2");
			}
			if (!userDoc.exists()) {
				console.log("If not found, try fetching the user as a Technician");
				userDocRef = doc(db, "Doctors", "Technician", "Technician", user.uid);
				userDoc = await getDoc(userDocRef);
				console.log("If not found, try fetching the user as a Technician 2");
			}
			if (userDoc.exists()) {
				console.log('User document found:', userDoc.data());
				setShowPopUp(false);
			} else {
				console.log('No such document!');
				document.getElementById("messageLogin").innerHTML = 'No matching document found!';
			}
		} catch (err) {
			document.getElementById("messageLogin").innerHTML = 'Failed to login. Please check your email and password.';
			console.error('Login error:', err);
		} finally {
			document.getElementById("buttoonLogin").innerHTML = "Sign in";
		}
	};


    function sendWelcomeEmail(email,name) {
        console.log("Sending welcome email for Google sign-up..."); // Log before sending email
        fetch("https://rexvetsemailserver.vercel.app/sendWelcomeEmailParent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                name: name || 'Anonymous',
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log("Email response:", data); // Log email response
        })
        .catch(emailError => {
            console.log("Error sending welcome email:", emailError); // Log email sending error
        });
    }
	
	
    const handleSignupSubmit = async (e) => {
        e.preventDefault();

        if (!name || !emailSignup || !phone || !state || !passwordSignup || !confirmPasswordSignup) {
            document.getElementById("messageSignup").innerHTML = 'Please fill all fields.';
            return;
        }
        if (passwordSignup.length < 8) {
            document.getElementById("messageSignup").innerHTML = 'Password must be at least 8 characters long.';
            return;
        }
        if (passwordSignup !== confirmPasswordSignup) {
            document.getElementById("messageSignup").innerHTML = 'Passwords do not match.';
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, emailSignup, passwordSignup);
            const user = userCredential.user;

            await setDoc(doc(db, 'Parents', user.uid), {
                Name: name,
                EmailAddress: emailSignup,
                PhoneNo: phone,
                State: state,
                RegistrationTime: serverTimestamp(),
            });

            sendWelcomeEmail(emailSignup,name);

            handleClosePopUp();
        } catch (err) {
            console.error('Error signing up:', err);
            document.getElementById("messageSignup").innerHTML = 'Error';
        }
    };

    const toggleSubmenu = (item) => {
        setActiveItem(item === activeItem ? null : item);
    };

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const handleMenuLinkClick = () => {
        if (window.innerWidth <= 991) {
            setIsMenuOpen(false);
        }
    };

    // const handleContactBtnClick = () => {
    //     setIsMenuOpen(false);
    // };

    const handleMenuCloseClick = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth >= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const menuItems = [
        {
            id: 'home',
            name: 'Home',
            linkName: '',
        },
        {
            id: 'pages',
            name: 'For pet parents',
            linkName: 'PetParents',
            subItems: [
                {
                    id: 'team',
                    displayName: 'Donate',
                    linkName: 'PetParents'
                },
                {
                    id: 'whatwetreat',
                    displayName: 'What we treat',
                    linkName: 'WhatWeTreat'
                },
                {
                    id: 'getaprescription',
                    displayName: 'Get a prescription',
                    linkName: 'GetAPrescription'
                },
            ]
        },
        {
            id: 'services',
            name: 'For vets & techs',
            linkName: '',
            subItems: [
                {
                    id: 'services',
                    displayName: 'Become a Rex Vet',
                    linkName: 'VetsandTechs'
                },
                {
                    id: 'blogs1',
                    displayName: 'Rex’s Health Hub ',
                    linkName: 'Blogs'
                },
                {
                    id: 'supportd',
                    displayName: 'Support',
                    linkName: 'Support'
                },
            ]
        },
        {
            id: 'blog',
            name: 'About',
            linkName: '#',
            subItems: [
                {
                    id: 'ourMission',
                    displayName: 'Our Mission',
                    linkName: 'Ourmission'
                },
                {
                    id: 'ourteam',
                    displayName: 'Our Team',
                    linkName: 'About'
                },
                {
                    id: 'blogDetails',
                    displayName: 'How it Works',
                    linkName: 'Works'
                },
            ]
        },
        {
            id: 'support',
            name: 'Support',
            linkName: 'Support'
        },
        {
            id: 'donate',
            name: 'Donate',
            linkName: 'Donate'
        }
    ];

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = () => {
        if (isPopupOpen) {
            setIsPopupOpen(false);
            setTimeout(() => setIsPopupVisible(false), 300); 
        } else {
            setIsPopupVisible(true);
            setTimeout(() => setIsPopupOpen(true), 10);
        }
    };
	return(
		<header className="header header-transparent rs-nav">
			<Sticky enabled={true} className="sticky-header navbar-expand-lg">
				<div className="menu-bar clearfix ">
					<div className="container-fluid clearfix navBarColor">
						<div className="menu-logo logo-dark">
							<NavLink to="/"><img src={logo} alt=""/></NavLink>
						</div>
						<button className={`navbar-toggler collapsed menuicon justify-content-end ${isMenuOpen ? 'open' : ''}`}
							type="button"
							onClick={toggleMenu}
							aria-label="Toggle navigation">
							<span></span>
							<span></span>
							<span></span>
						</button>
						<div className="secondary-menu">
							<div className="navbar-container">
                                {/* <button className={`user-icon-button ${isPopupOpen ? 'active' : ''}`} onClick={togglePopup}> <FontAwesomeIcon icon={isPopupOpen ? faTimes : faUserCircle} size="2x" /></button> */}
								<button className={`user-icon-button ${isPopupOpen ? 'active' : ''}`} onClick={togglePopup}>
									{loadingProfilePicture ? (
										<FontAwesomeIcon icon={faUserCircle} size="2x" />
									) : (
										<img src={profilePictureURL || 'https://firebasestorage.googleapis.com/v0/b/rexvets-database.appspot.com/o/Help%2Fuserplaceholder.png?alt=media&token=d43d5bcd-801f-4969-9d39-c9132c13eeb8'} alt="User" style={{ borderRadius: '50%', width: '40px', height: '40px' }} />
									)}
								</button>
                                {isPopupVisible && (
                                    <div className={`popup-menu ${isPopupOpen ? 'show' : ''}`}>
                                        <NavLink to="/FindAVet" style={{ fontFamily: 'Inter, sans-serif' }} className="popup-button">
                                            <FontAwesomeIcon icon={faVideo} /> Talk to a Vet
                                        </NavLink>
                                        {!user ? (
                                            <>
                                                <button style={{ fontFamily: 'Inter, sans-serif' }} className="popup-button" onClick={handleShowPopUp}>
                                                    <FontAwesomeIcon icon={faSignInAlt} /> Sign in
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                {user && (
                                                    <NavLink
                                                        style={{ fontFamily: 'Inter, sans-serif' }}
                                                        to={`/${userDocType === 'Parent' ? 'DashboardPetParents' : userDocType === 'Veterinarian' ? 'DashboardVetsandTechs' : 'DashboardVetsandTechs'}`}
                                                        className={({ isActive }) => 
                                                            `${isActive ? 'active' : ''} popup-button`
                                                        }
                                                    >
                                                        Dashboard
                                                    </NavLink>
                                                )}
                                                <button style={{ fontFamily: 'Inter, sans-serif' }} className="popup-button" onClick={handleSignOut}>
                                                    <FontAwesomeIcon icon={faSignInAlt} /> Sign out
                                                </button>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div> 
							<ul>
								<li className="btn-area">
									<NavLink to="/FindAVet" className="custom-buttonone">
										<svg viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
										<path d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"/>
										</svg>Talk to a vet
									</NavLink>
								</li>
							</ul>
						</div>
						<div className={`menu-links navbar-collapse collapse justify-content-end ${isMenuOpen ? 'show' : ''}`} id="menuDropdown">
							<div className="menu-logo">
								<NavLink to="/"><img src={logoWhite} alt=""/></NavLink>
							</div>
							<ul className="nav navbar-nav">	
								{menuItems.map((item) => (
									<li
										key={item.id}
											className={`${activeItem === item.id ? 'open' : ''}`}
											onClick={() => toggleSubmenu(item.id)}
										>
										{item.subItems ? (
											<NavLink to="#">
												{item.name}
												<i className={`fas fa-plus`}></i>
											</NavLink>
										) : (
											<NavLink to={`/${item.linkName}`} onClick={handleMenuLinkClick}>
												{item.name}
											</NavLink>
										)}
										{(isMobileView || activeItem === item.id) && item.subItems && (
											<ul className="sub-menu">
												{item.subItems.map((subItem, index) => (
													<li key={subItem.id}><NavLink to={`/${subItem.linkName}`} onClick={handleMenuLinkClick}><span>{subItem.displayName}</span></NavLink></li>
												))}
											</ul>
										)}
									</li>
								))}
							</ul>
							<ul className="social-media">
								<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/rexvets" className="btn btn-primary"><i className="fab fa-facebook-f"></i></a></li>
								<li><a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send/?phone=971547773686&text&type=phone_number&app_absent=0" className="btn btn-primary"><i className="fab fa-whatsapp"></i></a></li>
								<li><a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@rexvets" className="btn btn-primary"><i className="fab fa-tiktok"></i></a></li>
								<li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/rexvets" className="btn btn-primary"><i className="fab fa-instagram"></i></a></li>
							</ul>
							<div className="menu-close" onClick={handleMenuCloseClick}>
								<i className="ti-close"></i>
							</div>
						</div>
					</div>
				</div>
			</Sticky>

			{showPopUp && (
                <div style={overlayStyle}>
                    <RxCross1 style={closeIconStyle} onClick={handleClosePopUp} />
                    <div style={formStyle}>
                        <div style={tabContainerStyle}>
                            <button
                                style={activeTab === 'login' ? activeTabStyle : tabStyle}
                                onClick={() => handleTabSwitch('login')}
                            >
                                Sign in
                            </button>
                            <button
                                style={activeTab === 'register' ? activeTabStyle : tabStyle}
                                onClick={() => handleTabSwitch('register')}
                            >
                                Register
                            </button>
                        </div>
                        {activeTab === 'login' && (
                            <div>
                                <button onClick={handleGoogleSignIn} style={googleButtonStyle}>
                                    <FaGoogle style={googleIconStyle} /> Sign in with Google
                                </button>
								<form onSubmit={handleLoginSubmit}>
									<input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} style={inputStyle} />
									<input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} style={inputStyle} />
									<p style={forgotPasswordStyle}>Forgot Password</p>
									<div id='messageLogin' style={errorStyle}></div>
									<button id='buttoonLogin' type="submit" style={buttonStyle}>Sign in</button>
								</form>
                            </div>
                        )}
                        {activeTab === 'register' && (
                            <div>
                                <button onClick={handleGoogleSignUp} style={googleButtonStyle}>
                                    <FaGoogle style={googleIconStyle} /> Sign up with Google
                                </button>
								<form onSubmit={handleSignupSubmit}>
									<input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} style={inputStyle} />
									<input type="email" placeholder="Email" value={emailSignup} onChange={(e) => setEmailSignup(e.target.value)} style={inputStyle} />
									<input type="number" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} style={inputStyle} />
									<input type="text" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} style={inputStyle} />
									<input type="password" placeholder="Password" value={passwordSignup} onChange={(e) => setPasswordSignup(e.target.value)} style={inputStyle} />
									<input type="password" placeholder="Confirm Password" value={confirmPasswordSignup} onChange={(e) => setConfirmPasswordSignup(e.target.value)} style={inputStyle} />
									<div id='messageSignup' style={errorStyle}></div>
									<button id='buttonRegister' type="submit" style={buttonStyle}>Sign up</button>
								</form>
                            </div>
                        )}
                    </div>
                </div>
            )}
		</header>
	
	);
}


const overlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1000000',
};

const closeIconStyle = {
    cursor: 'pointer',
    marginBottom: '10px',
    position: 'absolute',
    top: '10px',
    right: '10px',
    fontSize: '25px',
    fontWeight: '900',
    color: 'white',
};

const googleButtonStyle = {
    width: '100%',
    margin: '10px 0px',
    backgroundColor: '#4285F4',
    color: 'white',
};

const googleIconStyle = {
    margin: '0',
    padding: '0',
    fontSize: '20px',
    marginRight: '10px',
};

const forgotPasswordStyle = {
    marginTop: '0px',
    textDecoration: 'underline',
    color: 'gray',
    cursor: 'pointer',
    fontSize: '10px',
    marginBottom: '5px',
};


const tabContainerStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
};

const tabStyle = {
    flex: 1,
    padding: '10px',
    backgroundColor: '#f1f1f1',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '8px 8px 0 0',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#002366',
};

const activeTabStyle = {
    ...tabStyle,
    color: '#fff',
    backgroundColor: '#002366',
    borderBottom: '2px solid #4285F4',
};

const formStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px'
};

const inputStyle = {
    width: '100%',
    height: '35px',
    fontSize: '13px',
    padding: '10px',
    margin: '5px 0',
    borderRadius: '4px',
    border: '1px solid #ddd'
};

const buttonStyle = {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: 'none',
    color: '#fff',
    fontSize: '13px',
    cursor: 'pointer',
    marginTop: '10px',
    height: '40px'
};
// eslint-disable-next-line
const errorStyle = {
    color: 'red',
    height: '13px',
    fontSize: '11px',
    width: '100%',
    textAlign: 'center',
    margin: '5px 0px'
};

export default NavbarMain;