import { IoIosColorPalette } from "react-icons/io";
import { FaPaw, FaCat, FaDog, FaBirthdayCake, FaTransgender } from 'react-icons/fa';
import { FaWeightScale } from 'react-icons/fa6';
import NavbarVetsandTechsDashboard from '../../../Navbars/NavbarVetsandTechsDashboard/index';
import { FaUserDoctor } from "react-icons/fa6";
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, getFirestore, updateDoc, onSnapshot, collection } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { useState, useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import '@fontsource/inter/100.css';  
import '@fontsource/inter/200.css'; 
import '@fontsource/inter/300.css';  
import '@fontsource/inter/400.css';  
import '@fontsource/inter/500.css'; 
import '@fontsource/inter/600.css'; 
import '@fontsource/inter/700.css';  
import '@fontsource/inter/800.css'; 
import '@fontsource/inter/900.css';

const AppointmentRoomPetParent = () => {
    const db = getFirestore();
    const auth = getAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    const quillRef = useRef(null);
    const [appointmentId, setAppointmentId] = useState(location.state?.appointmentId || '');
    const [appointmentData, setAppointmentData] = useState({});
    const [petData, setPetData] = useState({});
    const [newMessage, setNewMessage] = useState("");

    const getSenderName = async (userId) => {
        try {
            console.log("appointmentData:", appointmentData); 
            
           
            console.log(`Buscando usuario en la colección Technician con ID: ${userId}`);
            const technicianQuery = await getDoc(doc(db, "Doctors/Technician/Technician", userId));
            if (technicianQuery.exists()) {
                console.log("Usuario encontrado en Technician");
                return appointmentData.DoctorName; 
            }
    
           
            console.log(`Buscando usuario en la colección Veterinarian con ID: ${userId}`);
            const veterinarianQuery = await getDoc(doc(db, "Doctors/Veterinarian/Veterinarian", userId));
            if (veterinarianQuery.exists()) {
                console.log("Usuario encontrado en Veterinarian");
                console.log("appointmentData.VetName:", appointmentData.DoctorName); 
                return appointmentData.DoctorName; 
            }
    
            console.error('Usuario no encontrado en ninguna colección');
            return null;
        } catch (error) {
            console.error('Error buscando el nombre del remitente:', error);
            return null;
        }
    }; 

    useEffect(() => {
        const fetchAppointmentData = async () => {
            if (!appointmentId) return;
            const appointmentDocRef = doc(db, 'Appointments', appointmentId);
            const appointmentDoc = await getDoc(appointmentDocRef);
            if (appointmentDoc.exists()) {
                setAppointmentData(appointmentDoc.data());
                console.log(appointmentDoc.data());
            } else {
                console.error('No such document!');
            }
        };

        fetchAppointmentData();
    }, [appointmentId, db]);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }) + ', ' + date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };

    useEffect(() => {
        if (!appointmentId) return;

        const appointmentDocRef = doc(db, 'Appointments', appointmentId);

        const unsubscribe = onSnapshot(appointmentDocRef, (doc) => {
            if (doc.exists()) {
                setAppointmentData(doc.data());
            }
        });

        return () => unsubscribe();
    }, [appointmentId]);

    useEffect(() => {
        const fetchPetData = async () => {
            if (!appointmentData.ParentRefID || !appointmentData.PetRefID) return;

            const parentDocRef = doc(db, 'Parents', appointmentData.ParentRefID);
            const petsCollectionRef = collection(parentDocRef, 'pets');
            const petDocRef = doc(petsCollectionRef, appointmentData.PetRefID);

            const petDoc = await getDoc(petDocRef);
            if (petDoc.exists()) {
                setPetData(petDoc.data());
            } else {
                console.error('No such pet document!');
            }
        };

        fetchPetData();
    }, [appointmentData.ParentRefID, appointmentData.PetRefID, db]);

    useEffect(() => {
        const quillInstance = new Quill(quillRef.current, {
            theme: 'snow',
            modules: {
                toolbar: [
                    [{ 'header': '1'}, { 'header': '2' }, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['link', 'blockquote', 'code-block', 'image'],
                    [{ 'align': [] }],
                    ['clean']
                ]
            }
        });

        quillInstance.getModule('toolbar').addHandler('link', () => {
            const url = prompt('Enter the URL');
            if (url) {
                const range = quillInstance.getSelection();
                quillInstance.formatText(range.index, range.length, 'link', url);
            }
        });

        quillInstance.on('text-change', () => {
            setNewMessage(quillInstance.root.innerHTML);
        });

        return () => {
            quillInstance.off('text-change');
        };
    }, []);

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;

        const appointmentDocRef = doc(db, 'Appointments', appointmentId);

        // Verifica si el usuario está autenticado
        const userId = auth.currentUser?.uid;
        if (!userId) {
            console.error('User is not authenticated');
            return;
        }

        console.log(`User ID: ${userId}`);
        
        const senderName = await getSenderName(userId);
        console.log(`Sender Name: ${senderName}`);
        
        if (!senderName) {
            console.error('User not found in any collection.');
            return;
        }

        const message = {
            text: newMessage,
            sender: senderName,
            timestamp: new Date().toISOString()
        };

        try {
            const appointmentDoc = await getDoc(appointmentDocRef);
            if (appointmentDoc.exists()) {
                const existingMessages = appointmentDoc.data().Messages || [];
                await updateDoc(appointmentDocRef, {
                    Messages: [...existingMessages, message]
                });

                setNewMessage("");
                
                if (quillRef.current) {
                    quillRef.current.querySelector('.ql-editor').innerHTML = '';
                }

                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

                console.log("Message sent successfully");
            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };


    return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw' }}>
        <div className='dashboardmain' style={{ paddingBottom: '200px' }}>
            <NavbarVetsandTechsDashboard />

            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', borderRadius: '10px', width: '90%', marginTop: '50px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                    <h4  style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'2rem' }}>
                        <FaUserDoctor style={{ fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
                        Appointment Details <span  style={{ fontWeight: '300', fontFamily:'Inter, sans-serif', fontSize:'1rem', marginRight:'20px', color:'grey' }}>ID: {appointmentId}</span>
                    </h4>
                </div>
            </div>

            <div className='AppointmentCodeMain'>
                <div className='AppointmentCodeLeft'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'column', padding: '15px', marginBottom: '10px', borderRadius: '15px' }}>
                        <h5 style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'1.3rem' }}>Your appointment with:<br></br> <strong style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'1.2rem'  }} >{appointmentData.ParentName}</strong></h5>
                        <a target='_blank'  className='meetingButton' href={appointmentData.MeetingLink}>
                            <p style={{fontFamily:'Inter, sans-serif'}}>Meeting Link</p>
                        </a>
                        <p style={{ margin: '0', paddingTop: '10px', fontSize: '12px', marginTop: '0px', fontWeight: '500', fontFamily:'Inter, sans-serif' }}>{appointmentData.AppointmentDate} | {appointmentData.AppointmentTime}</p>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'column', padding: '20px', borderRadius: '15px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', flexDirection: 'column', borderBottom: '1px solid grey', paddingBottom: '10px' }}>
                            <h5  style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'1.3rem' }}>Patient & client information:</h5>
                            <p  style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'1rem' }}>{petData.Name}</p>
                            <p style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'0.9rem', marginBottom:'15px' }}>Patient</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {petData.Species === 'Cat' && <FaCat style={{ marginRight: '5px', fontSize: '0,9rem', color: '#002366' }} />}
                                {petData.Species === 'Dog' && <FaDog style={{ marginRight: '5px', fontSize: '0,9rem', color: '#002366' }} />}
                                {petData.Species !== 'Cat' && petData.Species !== 'Dog' && <FaPaw style={{ marginRight: '5px', fontSize: '12px'}} />}
                                <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem', marginLeft:'5px' }}>{petData.Species}</p>
                            </div>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaBirthdayCake style={{ marginRight: '5px', color: '#002366' }} /> {petData.AgeYears} years {petData.AgeMonths} months old</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaPaw style={{ marginRight: '5px', color: '#002366' }} /> {petData.Breed}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><IoIosColorPalette style={{ marginRight: '5px', color: '#002366' }} /> {petData.PetColor}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaTransgender style={{ marginRight: '5px', color: '#002366' }} /> {petData.Gender}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaWeightScale style={{ marginRight: '5px', color: '#002366' }} /> {petData.Weight} {petData.WeightUnit}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem', marginTop:'15px' }}><strong style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}>Spayed:</strong> {petData.Sprayed ? 'Yes' : 'No'}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><strong style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }} >Concerns:</strong> {appointmentData.PetConcerns?.join(', ')}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', flexDirection: 'column', marginTop: '8px' }}>
                            <p style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'1rem' }}>{appointmentData.ParentName}</p>
                            <p  style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}>Client</p>
                            <p  style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.8rem' }}>{appointmentData.ParentEmail}</p>
                        </div>
                    </div>
                </div>

                <div className='AppointmentCodeRight'>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', background: '#fbfbfb', borderRadius: '10px' }}>
                        <h5 style={{ fontWeight: '500', fontFamily:'Inter, sans-serif' }}>Messages</h5>
                        <div style={{ flex: 1, overflowY: 'scroll', padding: '10px', background: '#fff', borderRadius: '10px', maxHeight: '300px' }}>
                            {appointmentData.Messages?.map((msg, index) => (
                                <div key={index} style={{ fontWeight: '400', fontFamily:'Inter, sans-serif',  fontSize: '13px', marginBottom: '10px', padding:'15px',background:'linear-gradient(135deg, #003366, #001f4d)', borderRadius:'0px 20px 20px 20px', color:'white', width:'100%', maxWidth:'max-content'}}>
                                    <div>
                                        <strong  style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'13px', marginBottom:'15px' }}>{msg.sender}</strong>
                                        <p style={{  fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize: '10px', color: 'white', marginTop: '-3px'}}>
                                            {formatTimestamp(msg.timestamp)}
                                        </p>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                        <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                            <div ref={quillRef} style={{ height: '150px', marginBottom: '10px', background: 'white' }} />
                                <button onClick={handleSendMessage} style={{ padding: '10px 20px', borderRadius: '5px', border: 'none' }} >
                                    Send
                                </button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
);

};

export default AppointmentRoomPetParent;
