import { FaPrescription } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { FaInstagram, FaFacebook} from 'react-icons/fa'; 
import './Navbar.css';
import'../../1_MediaAssets/Styles/App.css';
import'../../1_MediaAssets/Styles/Pets.scss';
import Burger from '../../1_MediaAssets/Homepage/Burger.png';
import LogoWhite from '../../1_MediaAssets/Homepage/LogoR.png';
import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { LuLayoutDashboard } from "react-icons/lu";
import { FaUserDoctor } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";
// import { FaBell } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import { BiSolidHelpCircle } from "react-icons/bi";
import { getAuth, signOut } from "firebase/auth";
import { MdAlternateEmail } from "react-icons/md";
import { FaTiktok } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";

const Navbar = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsChecked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  function closeNavvy() {
    setIsChecked(!isChecked);
  };

  function signout(){
    const auth = getAuth();
    signOut(auth).then(() => {
        localStorage.removeItem('userId');
        console.log('Signed Out');
        window.location.href = '/';
    }).catch((error) => {
        console.log(error);
    });

}
  return (
    <>

    <nav className='Navbar2' ref={navRef}>
      <input type="checkbox" id="check" checked={isChecked} onChange={handleCheckboxChange} />
    
      <NavLink className="hidernav" to="/" style={{textAlign: 'left'}}>
        <img src={LogoWhite} alt='LogoWhite' style={{width: '33px', height: '38px', margin: '4px 4px 4px 8px'}} />
        <p style={{padding: '0', margin: '5px 0px 6px 0px', fontSize: '28px', color: 'white', display: 'flex', flexDirection: 'column'}}>RexVets <br></br>
          <span style={{color: '#8CC084', fontWeight: '900', width: '100%', marginTop: '-10px', fontSize: '12px', marginLeft: '2px'}}>
            for vets and techs  
          </span> 
        </p>  
      </NavLink>
      
      <label htmlFor="check" className="checkbtn2">
        <img src={Burger} alt='Burger' className='Burger' style={{width: '27px', height: '22px'}}></img>
      </label>
      
      <ul style={{background: '#002366', paddingTop: '20px'}}>
        
        <li style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <NavLink className="nav-linky" onClick={closeNavvy} to="/DashboardVetsandTechs" >
            <LuLayoutDashboard style={{fontSize: '20px', margin: '0px 15px 0px 15px'}}/>
            Dashboard
          </NavLink>
        </li>

        <li style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <NavLink className="nav-linky" onClick={closeNavvy} to="/AppointmentsVetsandTechs">
          <FaUserDoctor  style={{fontSize: '20px', margin: '0px 15px 0px 15px'}}/>
            Appointments
          </NavLink>
        </li>

        <li style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <NavLink className="nav-linky" onClick={closeNavvy} to="/RatesandAvailabilityVetsandTechs">
            <SlCalender  style={{fontSize: '20px', margin: '0px 15px 0px 15px'}}/>
            Rates & availability 
          </NavLink>
        </li>

        <li style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <NavLink className="nav-linky" onClick={closeNavvy} target="_blank" to="https://practice.securevetsource.com/login">
            <FaPrescription style={{fontSize: '20px', margin: '0px 15px 0px 15px'}}/>
            Prescriptions 
          </NavLink>
        </li>

        {/* <li style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <NavLink className="nav-linky" onClick={closeNavvy} to="/NotificationsVetsandTechs">
            <FaBell style={{fontSize: '20px', margin: '0px 15px 0px 15px'}}/>
            Notifications 
          </NavLink>
        </li> */}

        <li style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <NavLink className="nav-linky" onClick={closeNavvy} to="/AccountMenuVetsandTechs">
            <MdAccountCircle  style={{fontSize: '21px', margin: '0px 15px 0px 15px'}}/>
            Account 
          </NavLink>
        </li>
        
        <li style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <NavLink className="nav-linky" onClick={closeNavvy} to="/HelpVetsandTechs">
            <BiSolidHelpCircle  style={{fontSize: '21px', margin: '0px 15px 0px 15px'}}/>
            Help 
          </NavLink>
        </li>
      </ul>

      <div className='hidernav' style={{margin:'0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: '100%', padding: '20px 0px 20px 0px', flexDirection: 'column'}}>
        <button style={{fontSize: '12px', borderRadius: '10px', width: '90%', marginBottom: '10px', border: '1px solid #2458b8'}} onClick={signout}> <MdLogout style={{padding: '0', marginBottom: '2px'}} /> Sign out</button>
          <p style={{color: 'white', padding: '0', margin: '0', textAlign: 'center', fontSize:'12px', fontWeight:'300'}}>© 2024 Rex Vets. All rights reserved.</p>
          <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', gap: '10px'}}>
              <a href="https://wa.me/971547773686" style={{textDecoration: 'none', color: 'white'}}> 
                  <FaWhatsapp size={22} />
              </a>
              <a href="https://www.facebook.com/rexvets" style={{textDecoration: 'none', color: 'white'}}>
                  <FaInstagram size={20} />
              </a>
              <a href="https://www.facebook.com/rexvets" style={{textDecoration: 'none', color: 'white'}}>
                  <FaFacebook size={21} />
              </a>
              <a href="https://www.tiktok.com/@rexvets" style={{textDecoration: 'none', color: 'white'}}>
                  <FaTiktok size={20} />
              </a>
              <a href="mailto:support@rexvets.com" style={{textDecoration: 'none', color: 'white'}}>
                  <MdAlternateEmail size={24} />
              </a>
          </div>
      </div>
      
      
    </nav>
    </>
  );
};

export default Navbar;
