import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { IoIosColorPalette } from "react-icons/io";
import { FaPaw, FaCat, FaDog, FaBirthdayCake, FaTransgender} from 'react-icons/fa'; 
import { FaWeightScale} from 'react-icons/fa6';  
import NavbarPetParentsDashboard from '../../../Navbars/NavbarPetParentsDashboard/index';
import { FaUserDoctor } from "react-icons/fa6";
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, getFirestore, updateDoc, onSnapshot, collection } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { useState, useEffect, useRef } from 'react';

const AppointmentRoomPetParent = () => { 
    const db = getFirestore();
    const auth = getAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    const quillRef = useRef(null);
    const [appointmentId, setAppointmentId] = useState(location.state?.appointmentId || '');
    const [appointmentData, setAppointmentData] = useState({});
    const [petData, setPetData] = useState({});
    const [newMessage, setNewMessage] = useState("");

    useEffect(() => {
        const fetchAppointmentData = async () => {
            if (!appointmentId) return;
            const appointmentDocRef = doc(db, 'Appointments', appointmentId);
            const appointmentDoc = await getDoc(appointmentDocRef);
            if (appointmentDoc.exists()) {
                setAppointmentData(appointmentDoc.data());
                console.log(appointmentDoc.data());
            } else {
                console.error('No such document!');
            }
        };

        fetchAppointmentData();
    }, [appointmentId, db]);

    useEffect(() => {
        if (!appointmentId) return;

        const appointmentDocRef = doc(db, 'Appointments', appointmentId);
        
        const unsubscribe = onSnapshot(appointmentDocRef, (doc) => {
            if (doc.exists()) {
                setAppointmentData(doc.data());
            }
        });

        return () => unsubscribe();
    }, [appointmentId]);

    useEffect(() => {
        const fetchPetData = async () => {
            if (!appointmentData.PetRefID) return;

            const user = auth.currentUser;
            if (!user) return;

            const parentDocRef = doc(db, 'Parents', user.uid);
            const petsCollectionRef = collection(parentDocRef, 'pets');
            const petDocRef = doc(petsCollectionRef, appointmentData.PetRefID);

            const petDoc = await getDoc(petDocRef);
            if (petDoc.exists()) {
                setPetData(petDoc.data());
            } else {
                console.error('No such pet document!');
            }
        };

        fetchPetData();
    }, [appointmentData.PetRefID, db, auth.currentUser]);

    useEffect(() => {
        const quillInstance = new Quill(quillRef.current, {
            theme: 'snow',
            modules: {
                toolbar: [
                    [{ 'header': '1'}, { 'header': '2' }, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['link', 'blockquote', 'code-block', 'image'],
                    [{ 'align': [] }],
                    ['clean']
                ]
            }
        });

        quillInstance.on('text-change', () => {
            setNewMessage(quillInstance.root.innerHTML);
        });

        return () => {
            quillInstance.off('text-change');
        };
    }, []);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }) + ', ' + date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;

        const appointmentDocRef = doc(db, 'Appointments', appointmentId);
        const message = {
            text: newMessage,
            sender: appointmentData.ParentName,
            timestamp: new Date().toISOString()
        };

        await updateDoc(appointmentDocRef, {
            Messages: [...(appointmentData.Messages || []), message]
        });

        setNewMessage("");
        quillRef.current.querySelector('.ql-editor').innerHTML = '';
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };


    return (
        <>
        <NavbarPetParentsDashboard />
        <div style={{minHeight: '100vh', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw'}}>
            <div className='dashboardmain'>
    
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <h4 style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900' }}>
                            <FaUserDoctor style={{ fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
                            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                Appointment Details 
                                <span style={{ 
                                    fontSize: '13px', 
                                    color: 'grey', 
                                    whiteSpace: 'normal', 
                                    overflowWrap: 'break-word',
                                    wordWrap: 'break-word',
                                }}>
                                    (ID: {appointmentId})
                                </span>
                            </div>
                        </h4>
                    </div> 
                </div>
    
                <div className='AppointmentCodeMain'>
                    <div className='AppointmentCodeLeft'>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'column', padding: '15px', marginBottom: '10px', borderRadius: '15px', backgroundColor: '#f5f5f5'}}>
                            <h5 style={{fontWeight: '900'}}>Your appointment with Dr. {appointmentData.DoctorName}</h5> 
                            {/* eslint-disable-next-line */}
                            <a target='_blank' href={appointmentData.MeetingLink}>
                                <p style={{margin: '0', padding: '0', fontSize: '12px', marginTop: '0px', fontWeight: '900', color: '#0849c1', textDecoration: 'underline'}}>Meeting Link</p>
                            </a>
                            <p style={{margin: '0', padding: '0', fontSize: '12px', marginTop: '0px', fontWeight: '900'}}>{appointmentData.AppointmentDate} | {appointmentData.AppointmentTime}</p>
                        </div> 
    
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'column', padding: '20px', borderRadius: '15px', backgroundColor: '#f5f5f5'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', flexDirection: 'column', borderBottom: '1px solid grey', paddingBottom: '10px'}}>
                                <h5 style={{fontWeight: '900'}}>Patient & client information</h5>   
                                <p style={{margin: '0', padding: '0', fontSize: '14px', color: '#0849c1', fontWeight: '900'}}>{petData.Name}</p>
                                <p style={{padding: '0', fontSize: '10px', margin: '-5px 0px 3px 0px', fontWeight: '900'}}>Patient</p>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {petData.Species === 'Cat' && <FaCat style={{ marginRight: '5px' }} />}
                                    {petData.Species === 'Dog' && <FaDog style={{ marginRight: '5px' }} />}
                                    {petData.Species !== 'Cat' && petData.Species !== 'Dog' && <FaPaw style={{ marginRight: '5px' }} />}
                                    <p style={{margin: '0', padding: '0', fontSize: '12px'}}>{petData.Species}</p>
                                </div>
                                <p style={{margin: '0', padding: '0', fontSize: '12px'}}><FaBirthdayCake style={{ marginRight: '5px' }} /> {petData.AgeYears} years {petData.AgeMonths} months old</p>
                                <p style={{margin: '0', padding: '0', fontSize: '12px'}}><FaPaw style={{ marginRight: '5px' }} /> {petData.Breed}</p>
                                <p style={{margin: '0', padding: '0', fontSize: '12px'}}><IoIosColorPalette style={{ marginRight: '5px' }} /> {petData.PetColor}</p>
                                <p style={{margin: '0', padding: '0', fontSize: '12px'}}><FaTransgender style={{ marginRight: '5px' }} /> {petData.Gender}</p>
                                <p style={{margin: '0', padding: '0', fontSize: '12px'}}><FaWeightScale style={{ marginRight: '5px' }} /> {petData.Weight} {petData.WeightUnit}</p>
                                <p style={{margin: '0', padding: '0', fontSize: '12px'}}>Spayed: {petData.Sprayed ? 'Yes' : 'No'}</p>
                                <p style={{margin: '0', padding: '0', fontSize: '12px'}}>Concerns: {appointmentData.PetConcerns?.join(', ')}</p>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', flexDirection: 'column', marginTop: '8px'}}>
                                <p style={{margin: '0', padding: '0', fontSize: '14px', color: '#0849c1', fontWeight: '900'}}>{appointmentData.ParentName}</p>
                                <p style={{padding: '0', fontSize: '10px', margin: '-5px 0px 3px 0px', fontWeight: '900'}}>Client</p>
                                <p style={{margin: '0', padding: '0', fontSize: '12px'}}>{appointmentData.ParentEmail}</p>
                            </div>
                        </div>
                    </div>
    
                    <div className='AppointmentCodeRight'> 
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', background: '#f1f1f1', borderRadius: '10px' }}>
                            <h5 style={{ fontWeight: '900' }}>Messages</h5>
                            <div style={{ flex: 1, overflowY: 'scroll', padding: '10px', background: '#fff', borderRadius: '10px', maxHeight: '300px'}}>
                                {appointmentData.Messages?.map((msg, index) => (
                                    <div key={index} style={{ fontSize: '13px', marginBottom: '10px' }}>
                                        <div>
                                            <strong>{msg.sender}</strong>
                                            <p style={{ fontSize: '10px', color: 'grey', marginTop: '-3px'}}>
                                                {formatTimestamp(msg.timestamp)}
                                            </p>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                                    </div>
                                ))}
                                <div ref={messagesEndRef} />
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                <div ref={quillRef} style={{ height: '150px', marginBottom: '10px', background: 'white' }} />
                                <button onClick={handleSendMessage} style={{ padding: '10px 20px', borderRadius: '5px', border: 'none' }} >
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
    
}

export default AppointmentRoomPetParent;
